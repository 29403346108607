html, body {
  background-color: black;
  color: white;
}

.login-input {
  margin-top: 10px;
  margin-bottom: 10px;
}

#navbarScroll {
  min-height: auto;
  height: auto
}